import { makeStyles } from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection:'column',
        margin: 'auto',
        width:'100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },

    img:{
        width: '40%',
        maxWidth:'200px',
        animation: '$spin 2000ms infinite linear',
    },

    '@keyframes spin':{
        "0%": {
          transform: 'rotate(0deg);',
        },
        "100%": {
          transform: 'rotate(359deg);',
        }
      }
}))

function Loading(props) {

    const styles = useStyles();

    return (
        <div className={styles.root}>
                <img className={styles.img} src="logo512.png" />
                <h3>Carregando...</h3>
        </div>
    );
}

export default Loading;