import { Button, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../Provider/CartContext';
import Colors from '../../Styles/Colors';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        padding: '20px',
        [theme.breakpoints.down('lg')]: {
            width:'50%',
            margin:'auto',
        },
        [theme.breakpoints.down('md')]: {
            width:'auto',
            margin:'auto',
        },
    },

    button: {
        minHeight: '130px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        '& p': {
            margin: '0',
            fontWeight: 'bold',
        }
    },

    backButton: {
        backgroundColor: Colors.dark_green,
    },
    sendButton: {
        backgroundColor: Colors.dark_red,
    },
    produto: {
        backgroundColor: Colors.orange_light,
        display: 'flex',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 15px',
        borderRadius: '20px',
        position: 'relative',
    },
    produtoQuantidade: {
        backgroundColor: Colors.background_light,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: '70px',
        height: '70px',
        fontSize: '30px',
        fontWeight: 'bold',
        borderRadius: '70px',
        marginRight: '15px',
    },
    produtoDescricao: {
        textAlign: 'left',
        minWidth: '100px',
        '& h3': {
            margin: 0,
        },
        '& p': {
            margin: 0,
        }
    },
    produtoTotal: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    produtoDelete: {
        position: 'absolute',
        top: '-10px',
        right: "-10px",
        backgroundColor: Colors.dark_orange,
        padding: "7px",
        '&:hover': {
            backgroundColor: Colors.dark_orange,
        }
    },
    produtoOpcoes: {
        backgroundColor: Colors.orange_light,
        padding: "10px",
        width: '70%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        borderRadius: '0 0 15px 15px',

    },
    opcoesText: {
        backgroundColor: Colors.background_light,
        margin: '5px',
        borderRadius: '5px',
        padding: '3px',
        border: "solid 2px",
        borderColor: Colors.dark_orange
    },
    rootProduto: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    totalArea:{
        display:'flex',
        justifyContent:'space-between',
        padding:'0 20px',
        '& h2':{
            margin:'0',
            fontSize:'25px',
        }
    }

}))

function Chart(props) {
    const styles = useStyles();

    const { cart, RemoveItem, cartTotal } = useContext(CartContext);

    if (cart.length <= 0) {
        return (

            <div className={styles.root}>

                <h2>Meu Carrinho</h2>
                <h3>Adicione alguns itens no carrinho para proseguir com seu pedido! 😀</h3>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={8}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Paper elevation={3} className={`${styles.backButton} ${styles.button}`}>
                                <AddShoppingCartIcon style={{ fontSize: '40px' }} />
                                <p>Adicionar Produtos</p>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        )
    }
    return (
        <div className={styles.root}>

            <h2>Meu Carrinho</h2>

            <Grid container justifyContent={'center'} spacing={3}>

                {cart.map((item, index) => (
                    <Grid item xs={12} className={styles.rootProduto}>

                        <Paper className={styles.produto}>
                            <IconButton onClick={() => RemoveItem(index)} className={styles.produtoDelete}>
                                <Delete style={{ color: 'black' }} />
                            </IconButton>
                            <div className={styles.produtoQuantidade}>
                                {item.quantidade}
                            </div>

                            <div className={styles.produtoDescricao}>
                                <h3>{item.nome}</h3>
                                <p>{item.unitario}</p>
                            </div>

                            <div className={styles.produtoTotal}>{item.total}</div>
                        </Paper>
                        <Paper className={styles.produtoOpcoes} style={{ display: item.opcoes?.length > 0 ? 'flex' : 'none' }}>
                            {item.opcoes.map((opc, indexOpc) => (
                                <div key={indexOpc} className={styles.opcoesText}>{opc.tipo == "1" ? opc.quantidade : null} {opc.descricao}</div>
                            ))}
                        </Paper>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <div className={styles.totalArea}>
                        <h2>Subtotal:</h2>
                        <h2>{cartTotal}</h2>
                    </div>

                </Grid>

                <Grid item xs={6}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <Paper elevation={3} className={`${styles.backButton} ${styles.button}`}>
                            <AddShoppingCartIcon style={{ fontSize: '40px' }} />
                            <p>Adicionar Mais Produtos</p>
                        </Paper>
                    </Link>
                </Grid>

                <Grid item xs={6}>
                    <Link to='/send' style={{ textDecoration: 'none' }}>
                        <Paper elevation={3} className={`${styles.button} ${styles.sendButton}`}>
                            <ShoppingCartIcon style={{ fontSize: '40px' }} />
                            <p>Confirmar Pedido</p>
                        </Paper>
                    </Link>
                </Grid>
            </Grid>


        </div>
    );
}

export default Chart;