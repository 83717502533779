import { Button, CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../../Provider/CartContext';
import Colors from '../../Styles/Colors';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BackspaceIcon from '@material-ui/icons/Backspace';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SendIcon from '@material-ui/icons/Send';
import { Send } from '@material-ui/icons';
import firebase from '../../Services/firebase';
import { ImTicket } from 'react-icons/im';
import { tratarValor, tratarValorApi } from '../../Services/functions';
import MaskedInput from "react-text-mask";

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        padding: '20px',
        [theme.breakpoints.up('md')]: {
            width: '50%',
            margin: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            width: 'auto',
            margin: 'auto',
        },
    },

    button: {
        minHeight: '130px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        cursor: 'pointer',
        '& p': {
            margin: '0',
            fontWeight: 'bold',
        }
    },

    backButton: {
        backgroundColor: Colors.dark_green,
    },
    sendButton: {
        backgroundColor: Colors.dark_red,
    },
    produto: {
        backgroundColor: Colors.orange_light,
        width: '100%',
        borderRadius: '5px',
    },
    produtoDescricao: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        justifyContent: 'space-between',
    },
    produtoTotal: {
        fontSize: '18px',
        fontWeight: 'bold',
    },

    produtoOpcoes: {
        backgroundColor: Colors.orange_light,
        padding: "5px",
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: '0 0 15px 15px',

    },
    opcoesText: {
        backgroundColor: Colors.dark_orange,
        margin: '3px',
        borderRadius: '5px',
        padding: '2px 10px',
    },
    rootProduto: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    warning: {
        color: 'red',
    },
    totalArea: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px',
        '& h2': {
            margin: '0',
            fontSize: '35px',
        },
        '& h3': {
            margin: '0',

        }
    }

}))

function InputMaskCustomPhone(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={(rawValue) => {
                if (rawValue.replace(/(\D)+/g, '').length > 10) {
                    return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                } else {
                    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                }

            }}
            guide={false}
        />
    );
}

function SendRequest(props) {
    const navigate = useNavigate();
    const styles = useStyles();
    const { cart, cartTotal, AddItem, RemoveItem } = useContext(CartContext);
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [local, setLocal] = useState('Retirar no Balcão');
    const [observacao, setObservacao] = useState('');
    const [cupom, setCupom] = useState('');
    const [nomeError, setNomeError] = useState(false);
    const [nomeMessage, setNomeMessage] = useState('');

    const [cupomLoading, setCupomLoading] = useState(false);

    const [cupomDescontoReal, setCupomDescontoReal] = useState('');
    const [total, setTotal] = useState(cartTotal);
    const [showCupomField, setShowCupomField] = useState(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const [showBonus, setShowBonus] = useState(false);
    const [aplicouCupom, setAplicouCupom] = useState(false);
    const [cupomObj, setCupomObj] = useState({});
    const [desconto, setDesconto] = useState('');

    const getOpcs = (opc) => {
        // if(opc.length <= 0) return '';

        let text = '%0a';
        opc.map((item) => { //c.tipo == "1" ? opc.quantidade : nul
            if (item.tipo === '1') {
                text += `${item.quantidade} ${item.descricao}%0a`
            } else {
                text += `${item.descricao}%0a`
            }
        })

        return text;
    }

    const getProdutos = () => {
        let text = '------%0a';
        cart.map((item) => {
            text += `${item.quantidade} ${item.nome} ${getOpcs(item.opcoes)}------%0a`
        })
        return text;
    }

    function cancelarPedido() {
        removerCupom();
        navigate("/");
    }

    function sendRequest() {

        if (nome === '') {
            setNomeError(true);
            setNomeMessage("Você deve adicionar seu nome ao pedido");
            return;
        }

        const dados = {
            cliente: nome,
            telefone: telefone == '' ? null : telefone,
            local: local,
            subtotal: cartTotal,
            total: total,
            produtos: cart,
            cupom: aplicouCupom ? cupom : null,
            desconto: desconto ? desconto : null,
            status: '0', //primeio '1'
            data: new Date().toLocaleDateString('pt-BR').toString(),
            hora: moment().format('LT'),
            observacao: observacao,
            agendamento: false,
        }
        console.log(dados);

        firebase.database().ref('Pedidos').push(dados);
        firebase.database().ref(`Cupons/${cupom}/utilizado_por`).push({ nome: nome, telefone: telefone });
        
        const mensagem = `
            🤖Pedido Online🤖%0aNome: ${nome},%0aTelefone: ${telefone},%0aLocal: ${local},%0aCupom: ${dados.cupom == null ? '' : dados.cupom},%0aObservação: ${observacao},%0aTotal: ${total}%0aProdutos:%0a${getProdutos()}`
        console.log(mensagem);
        window.open(`https://wa.me/5548988244605?text=${mensagem}`, '_blank');
    }

    if (cart.length <= 0) {
        return (

            <div className={styles.root}>

                <h2>Meu Carrinho</h2>
                <h3>Adicione alguns itens no carrinho para proseguir com seu pedido! 😀</h3>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={8} md={4}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Paper elevation={3} className={`${styles.backButton} ${styles.button}`}>
                                <AddShoppingCartIcon style={{ fontSize: '40px' }} />
                                <p>Adicionar Produtos</p>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        )
    }



    async function handleCupom() {
        setCupomLoading(true);
        const cp = await verificarCupom();
        if (cp) {
            await aplicarCupom(cp);
        }
        setCupomLoading(false);
    }

    async function verificarCupom() {
        if (cupom == '') return window.alert("Insira um cupom valido!");
        const cp = (await firebase.database().ref(`Cupons/${cupom}`).get()).toJSON();
        console.log(cp);
        if (!cp) return window.alert('Cupom inválido');

        const hoje = moment().format('MM/DD/YYYY');
        if (!((hoje >= cp.inicio) && (hoje <= cp.fim))) return window.alert("O cupom expirou 😔");

        const utilizado_por = cp.utilizado_por ? Object.values(cp.utilizado_por) : new Array;

        if (cp.multiple_users === "false" && utilizado_por.length > 0) return window.alert("Alguém já utilizou esse cupom 😢");

        if (cp.condicao === "1") {
            const tot = parseFloat(tratarValor(cartTotal));
            const min = parseFloat(tratarValor(cp.valor_min))
            if (tot < min) return window.alert("Seu carrinho não atende aos requisitos do cupom! Adicione mais itens ao seu pedido para utilizar o cupom!")
        }

        if (cp.condicao === "2") {
            const quantMin = Number(cp.quant_min);
            const categoriaMin = cp.categoria_quant_min;
            const quantAtual = verificarQuantCat(categoriaMin);
            if (quantAtual < quantMin) return window.alert(`Você precisa ter no mínimo ${quantMin} de itens do tipo ${categoriaMin} no seu carrinho para utilizar esse cupom!`)
        }

        return cp;

    }

    const verificarQuantCat = (cat) => {
        let quant = 0;
        cart.map((item) => {
            if (item.tipo === cat) {
                quant += item.quantidade;
            };
        })
        return quant;
    }


    async function aplicarCupom(cp) {

        switch (cp.tipo) {
            case "0":
                const tot = parseFloat(tratarValor(cartTotal));
                const desc = parseFloat(cp.desconto);
                const pdesc = (Number(tot) * Number(desc)) / 100
                const novoTotal = Number(tratarValor(cartTotal)) - Number(pdesc);
                setCupomDescontoReal(tratarValorApi('R$ ' + pdesc.toFixed(2)));
                setTotal(tratarValorApi('R$ ' + novoTotal.toFixed(2)));
                setShowCupomField(false);
                setShowDiscount(true);
                setDesconto(desc);
                break;
            case "1":
                retirarItemBonus();
                let bonus = { ...cp.produto };
                bonus.nome = bonus.nome + ` (CUPOM: ${cupom})`
                bonus.total = "R$ 0,00";
                bonus.unitario = "R$ 0,00";
                bonus.quantidade = cp.quantidade;
                bonus.opcoes = [];
                bonus.bonus = true;
                AddItem(bonus);
                setShowCupomField(false);
                setShowDiscount(false);
                setShowBonus(true);
                break;

        }
        setAplicouCupom(true);
        setCupomObj(cp);
    }

    function removerCupom() {
        setShowCupomField(false);
        setShowBonus(false);
        setShowDiscount(false);
        setAplicouCupom(false);
        setCupomObj({});
        setCupom('');
        retirarItemBonus();
        setTotal(cartTotal);
    }

    function retirarItemBonus() {

        cart.map((item, index) => {
            if (item.bonus) {
                RemoveItem(index);
            }
        })

    }



    return (
        <div className={styles.root}>
            <img src='/logo.png' width={'150px'} height={'auto'}></img>
            <h2 style={{ marginTop: '0' }}>Finalizar Pedido</h2>

            <Grid container justifyContent={'center'} alignItems='center' spacing={3}>

                {cart.map((item, index) => (
                    <Grid item xs={12} className={styles.rootProduto}>

                        <Paper className={styles.produto} style={{ backgroundColor: item.bonus ? "#f54242" : undefined, color: item.bonus ? "white" : undefined, fontWeight: item.bonus ? "bold" : undefined }}>
                            <div className={styles.produtoDescricao}>
                                <div>
                                    {item.quantidade} {item.nome}
                                </div>

                                <div className={styles.produtoTotal}>{item.total}</div>
                            </div>
                            <div className={styles.produtoOpcoes} style={{ display: item.opcoes?.length > 0 ? 'flex' : 'none' }}>
                                {item.opcoes.map((opc, indexOpc) => (
                                    <div key={indexOpc} className={styles.opcoesText}>{opc.tipo == "1" ? opc.quantidade : null} {opc.descricao}</div>
                                ))}
                            </div>

                        </Paper>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        label="Nome"
                        autoComplete="name"
                        color='secondary'
                        value={nome}
                        error={nomeError}
                        helperText={nomeMessage}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => setTelefone(e.target.value)}
                        fullWidth
                        value={telefone}
                        color='secondary'
                        label="Telefone"
                        placeholder='Informe um número de WhatsApp para receber atualizações do seu pedido!'
                        variant="outlined"
                        InputProps={{ inputComponent: InputMaskCustomPhone }}
                        id="campoTelefone"
                        autoComplete={"on|off"}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline={true}
                        rows={3}
                        variant='outlined'
                        label="Observação"
                        color='secondary'
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth color='secondary' variant='outlined' style={{ textAlign: 'left' }}>
                        <InputLabel id="retirada-label">Local</InputLabel>
                        <Select
                            labelId="retirada-label"
                            id="retirada"
                            value={local}
                            onChange={(e) => setLocal(e.target.value)}
                            label="Local"
                        >
                            <MenuItem value={'Retirar no Balcão'}>Retirar no Balcão</MenuItem>
                            <MenuItem value={'Comer no Local'}>Comer no Local</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={8}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        label="Cupom"
                        color='secondary'
                        value={cupom}
                        disabled={aplicouCupom}
                        onChange={(e) => setCupom(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={4} style={{ display: aplicouCupom ? 'none' : 'block' }}>
                    <Button
                        variant='contained'
                        fullWidth color='secondary'
                        style={{ height: '56px', fontWeight: "bold" }}
                        onClick={() => handleCupom()}
                        disabled={cupomLoading} >


                        <Typography variant='button' style={{ display: cupomLoading ? 'none' : 'block', fontSize: '15px' }}>
                            Aplicar Cupom <ImTicket style={{ marginLeft: '10px', fontSize: '20px' }} />
                        </Typography>

                        <Typography style={{ display: cupomLoading ? 'block' : 'none' }}>
                            <CircularProgress />
                        </Typography>


                    </Button>
                </Grid>

                <Grid item xs={12} md={4} style={{ display: aplicouCupom ? 'block' : 'none' }}>
                    <Button variant='contained' fullWidth color='secondary' style={{ height: '56px', fontWeight: "bold" }} onClick={() => removerCupom()}>Remover Cupom <ImTicket style={{ marginLeft: '10px', fontSize: '20px' }} /> </Button>
                </Grid>

                <Grid item xs={12} style={{ display: showDiscount ? 'block' : 'none' }}>
                    <Paper className={styles.produto}><Typography variant='h5' style={{ padding: '30px', color: 'white' }}>🥳🥳 Uhull!! Você obteve {cupomObj.desconto}% de desconto nessa compra!</Typography></Paper>
                </Grid>

                <Grid item xs={12} style={{ display: showBonus ? 'block' : 'none' }}>
                    <Paper className={styles.produto}><Typography variant='h5' style={{ padding: '30px', color: 'white' }}>🥳🥳 Uhull!! Você obteve {cupomObj.quantidade} {cupomObj.produto?.nome} nessa compra!</Typography></Paper>
                </Grid>




                <Grid item xs={12} style={{ display: showDiscount ? 'block' : 'none' }}>
                    <div className={styles.totalArea}>
                        <h3>Subtotal:</h3>
                        <h3>{cartTotal}</h3>
                    </div>
                    <div className={styles.totalArea} style={{ paddingBottom: '15px', borderBottom: 'solid 1px gray' }}>
                        <h3>Desconto Aplicado:</h3>
                        <h3>{cupomDescontoReal}</h3>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={styles.totalArea}>
                        <h2>Total:</h2>
                        <h2>{total}</h2>
                    </div>

                </Grid>

                <Grid item xs={6}>
                    <Paper onClick={() => cancelarPedido()} elevation={3} className={`${styles.sendButton} ${styles.button}`}>
                        <BackspaceIcon style={{ fontSize: '40px' }} />
                        <p>Cancelar</p>
                    </Paper>
                </Grid>

                <Grid item xs={6}>

                    <Paper onClick={() => sendRequest()} elevation={3} className={`${styles.button} ${styles.backButton}`}>
                        <SendIcon style={{ fontSize: '40px' }} />
                        <p>Enviar Pedido</p>
                    </Paper>
                </Grid>
            </Grid>


        </div>
    );
}

export default SendRequest;