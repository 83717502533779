import React, { createContext, useState } from 'react';
import { tratarValor, tratarValorApi } from '../Services/functions';

export const CartContext = createContext({});

export const CartProvider = ( { children } ) => {


    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState('');
    const [qtdeItens, setQtdeItens] = useState(0);

    function AddItem(item){
        let carte = cart;
        carte.push(item)
        calcTotal(carte);
        calcQtdeItens(carte);
        setCart([...carte]);
    }

    function RemoveItem(index){
        let carte = cart;
        carte.splice(index, 1);
        calcTotal(carte);
        calcQtdeItens(carte);
        setCart([...carte]);
    }

    function calcTotal(carte){

        let tot = 0;
        
        carte.map((item) =>{
            tot += (Number(tratarValor(item.total)));
        })

        const totApi = tratarValorApi('R$ ' + tot.toFixed(2));
        setCartTotal(totApi);

    }

    const calcQtdeItens = (carte) => {
        let soma = 0;
        carte.map((item) => soma+=item.quantidade)
        setQtdeItens(soma);
    }

    function clearCart(){
        setCart([]);
        setCartTotal('R$ 0,00');
    }

    return(

        <CartContext.Provider value={{cart, AddItem, RemoveItem, cartTotal, clearCart, qtdeItens}}>{children}</CartContext.Provider>

    )

}