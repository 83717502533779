import React, {useEffect, useState} from 'react';
import firebase from '../Services/firebase';
import {Button, makeStyles} from '@material-ui/core';
import colors from '../Styles/Colors'

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'inline-block',
    },
    aroundImage:{
        width: '100px',
        borderRadius: '100px',
        padding: '2px',
        border:'solid #f57542 5px',
        margin:'5px 0',
        display:'flex',
        cursor: 'pointer',

        '&:hover':{
            borderColor: colors.dark_orange,
        },

        '&:focus':{
            borderColor: colors.dark_red,
        }
    },
    roundImg: {
        
        width: '100%',
        borderRadius: '100px',
    
    },
    text:{
        fontSize: '20px',
    }
}))
function TypeButton(props) {

   const styles = useStyles();

    const [imgUrl, setImgUrl] = useState('')

    useEffect(() =>{

        firebase.storage().ref(`Tipos/${props.nome}.jpg`).getDownloadURL().then(resu => setImgUrl(resu));

    }, [])

    return (
        <div className={styles.root}>
            <Button onClick={props.onClick} style={{borderRadius:'100px', margin:'0'}}>
            <div className={styles.aroundImage}>
                <img className={styles.roundImg} onLoad={props.ImageLoad} src={`${imgUrl}`}/>
            </div>
            </Button>
            <div className={styles.text}>
            {props.nome}
            </div>
        </div>
    );
}

export default TypeButton;