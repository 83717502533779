import { Badge, Button, Grid, IconButton, makeStyles, withStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from '../../Components/Carousel';
import Produto from '../../Components/Produto';
import TypeButton from '../../Components/TypeButton';
import firebase from '../../Services/firebase';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import Colors from '../../Styles/Colors';
import { CartContext } from '../../Provider/CartContext';
import Loading from '../../Components/Loading';

const useStyles = makeStyles((theme) => ({

    typesArea: {
        width: '100%',
        whiteSpace: 'nowrap',
        margin: '20px auto',
        overflowX: 'scroll',
        //textAlign:'center',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    cartButtonArea: {
        padding: '10px',
        position: 'sticky',
        bottom: '0',
        display: 'flex',
        justifyContent: 'flex-end',
        pointerEvents: 'none',
        '& a': {
            pointerEvents: 'all',
        }
    },
    cartButton: {
        backgroundColor: Colors.dark_orange,
        border: 'solid white 2px'
    },
}))
const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -10,
        top: 0,
        border: `2px solid white`,
        padding: '0 4px',
        backgroundColor: Colors.dark_green
    },
}))(Badge);
export default function Main(props) {

    const styles = useStyles();
    const [types, setTypes] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [typeSelected, setTypeSelected] = useState('Inicio');
    const { qtdeItens } = useContext(CartContext);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getTypes();
        //getProducts();
    }, [])

    function getProducts(categoria) {
        let prods = null;

        if (!categoria) {
            prods = firebase.database().ref('Produtos').orderByChild('favorito').equalTo(true);
        } else {
            prods = firebase.database().ref('Produtos').orderByChild('tipo').equalTo(categoria);
        }
        prods.once('value', (snap) => {

            let novaLista = [];

            snap.forEach((item) => {
                novaLista.push({ ...item.val(), id: item.key })
            })

            novaLista = novaLista.filter((item) => item.disponivel == true);

            setProdutos([...novaLista]);
            setLoading(false);
        })

    }

    function getTypes() {
        const tipos = firebase.database().ref('Tipos');
        tipos.on('value', (snap) => {
            let novaLista = [];
            novaLista.push({ nome: 'Inicio', id: '0' })
            snap.forEach((item) => {
                novaLista.push({ ...item.val(), id: item.key })
            })
            console.log(novaLista);
            setTypes(novaLista);
            getProducts();
        })
    }

    function handleSelectType(e) {

        setTypeSelected(e);

        console.log(e);
        if (e === 'Inicio') {
            getProducts();
        } else {
            getProducts(e);
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ width: "100%", textAlign: 'center' }}>

            <div className={styles.typesArea}>
                {types.map((item) => (
                    <TypeButton ImageLoad={() => setLoading(false)} onClick={() => handleSelectType(item.nome)} nome={item.nome} />
                ))}
            </div>

            {typeSelected === 'Inicio' ? (
                <>
                    <Carousel />
                    <h3>Os Mais Pedidos</h3>
                </>
            ) : null}

            <div>
                <Grid container>

                    {produtos.map((item) => (
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Produto produto={item} tipo={produtos} />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={styles.cartButtonArea}>
                <Link to='/carrinho'>
                    <IconButton className={styles.cartButton} color='secondary'>
                        <StyledBadge badgeContent={qtdeItens} color="secondary">
                            <ShoppingCartIcon style={{ color: 'white' }} />
                        </StyledBadge>

                    </IconButton>
                </Link>
            </div>
        </div>
    );
}