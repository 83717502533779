import { Button, Checkbox, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../Provider/CartContext';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Colors from '../Styles/Colors';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { tratarValor, tratarValorApi } from '../Services/functions';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        width: '80%',
        maxWidth: '500px',
        maxHeight: '80%',
        overflow: 'auto',
        backgroundColor: Colors.background_light,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 0),
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        textAlign: 'center',
    },
    title: {
        textAlign: 'center',
        fontSize: '30px',
    },
    dropDownButton: {
        display: 'flex',
        backgroundColor: Colors.orange_light,
        justifyContent: 'space-between',
        padding: '0 30px',
        alignItems: 'center',
        borderRadius: '20px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    opcoesView: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.dark_orange,
        borderRadius: '0 0 10px 10px',
        width: '80%',
        margin: '0 auto',
        padding: '10px',
        // maxHeight: '300px',
        // overflow:'auto'
    },
    itemView: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& p': {
            textAlign: 'left',
            margin: '0',
        }
    },
    quantidadeArea: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        fontSize: '30px',
    },
    confirmArea: {
        backgroundColor: Colors.orange_light,
        position: 'sticky',
        left: '0',
        bottom: '-1px',
        width: '100%',
    },
    confirmAreaDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px',
    },
    totalArea: {
        textAlign: "left",
        '& h3': {
            margin: '0',
        },
        '& p': {
            margin: '0',
        },
    },
    cartButton: {
        textAlign: 'left',
        maxWidth: '60%',
        backgroundColor: Colors.dark_green,

        '&:hover': {
            backgroundColor: '#1f6026'
        }
    },
    exit: {
        position: 'absolute',
        top: '0',
        right: '0',
    }
}))


function SelectModal({ produto, handleClose }) {

    const styles = useStyles();
    const navigate = useNavigate();

    const { nome, valor, descricao, opcoes, tipo, id } = { ...produto };
    const { AddItem } = useContext(CartContext);
    const [opcs, setOpcs] = useState([]);
    const [quantidade, setQuantidade] = useState(1);
    const [total, setTotal] = useState('');
    const [visibleOpcoes, setVisibleOpcoes] = useState(false);
    const [visibleAdicionais, setVisibleAdicionais] = useState(false);

    useEffect(() => {
        setTotal(valor);

        opcoes?.map((item) => {
            if (item.tipo == 0) {
                item.checked = false
            } else {
                item.quantidade = 0
            }

        });

        setOpcs(opcoes ? opcoes : []);


    }, [])


    const somarTotal = (qtde, opc) => {
        let sumOpcs = 0;
        opc.filter((item) => item.tipo === "1").map((item) => {
            if (item.quantidade > 0) {
                sumOpcs += (tratarValor(item.valor) * Number(item.quantidade));
            }

        })
        const tot = (Number(tratarValor(valor)) + Number(sumOpcs)) * qtde;
        const totApi = tratarValorApi('R$ ' + tot.toFixed(2));
        setTotal(totApi);
    }

    function handleAddItem() {
        let novasOpcs = [];
        opcs.forEach((item) => {
            if (item.tipo === '0') {
                if (item.checked) {
                    novasOpcs.push(item);
                }
            } else {
                if (item.quantidade > 0) {
                    novasOpcs.push(item);
                }
            }
        })

        const dados = {
            nome: nome,
            quantidade: quantidade,
            tipo: tipo,
            total: total,
            unitario: tratarValorApi('R$ ' + (Number(tratarValor(total)) / Number(quantidade)).toFixed(2)),
            opcoes: novasOpcs
        }

        AddItem(dados);
        navigate('/carrinho')

    }

    function handleOpcoes(id) {
        //let novoVetor = opcoesSelecionadas;
        let novoVetor = [...opcs]
        novoVetor.map((opcao) => {
            if (opcao.id === id) {
                if (opcao.checked) {
                    opcao.checked = false
                } else {
                    opcao.checked = true;
                }
            }
        })
        setOpcs(novoVetor);
        

    }

    function handleSomaOpcoes(id) {
        //let novoVetor = opcoesSelecionadas;
        console.log(id);
        let novoVetor = [...opcs]
        novoVetor.map((opcao) => {
            console.log(opcao);
            if (opcao.id === id) {
                console.log('entrou');
                if(Number(opcao.quantidade) < 4){
                    opcao.quantidade+=1;
                }
            }
        })
        setOpcs(novoVetor);
        somarTotal(quantidade, novoVetor);
    }

    function handleDiminuiOpcoes(id) {
        //let novoVetor = opcoesSelecionadas;
        console.log(id);
        let novoVetor = [...opcs]
        novoVetor.map((opcao) => {
            console.log(opcao);
            if (opcao.id === id) {
                console.log('entrou');
                if(Number(opcao.quantidade) > 0){
                    opcao.quantidade-=1;
                }
            }
        })
        setOpcs(novoVetor);
        somarTotal(quantidade, novoVetor);
    }

    return (
        <div className={styles.root}>
            <IconButton onClick={handleClose} className={styles.exit}>
                <HighlightOffIcon style={{ fontSize: '30px', color: Colors.dark_red }} />
            </IconButton>
            <div style={{ padding: '20px' }}>
                <h2 className={styles.title}>{nome}</h2>
                <p>
                    {descricao}
                </p>


                {opcs ? opcs.filter((item) => item.tipo === "0").length > 0 ? (
                    <Paper className={styles.dropDownButton} onClick={() => setVisibleOpcoes(!visibleOpcoes)}>
                        <h4 className={'styles.mainTextos'}>Opcionais</h4>
                        <div className={'styles.dropDownButtonView'}>
                            {visibleOpcoes ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </div>
                    </Paper>

                ) : null : null}
                <Paper style={{ display: opcs.filter((item) => item.tipo === "0").length > 0 ? 'flex' : 'none' }} className={styles.opcoesView}>

                    {opcs && visibleOpcoes ? opcs.filter((opc) => opc.tipo === "0").map((opc, indexOpc) => (
                        <div key={opc.id} className={styles.itemView}>
                            <p>{opc.descricao}</p>
                            <div><Checkbox style={{ color: 'black' }} checked={opc.checked} onChange={() => handleOpcoes(opc.id)}/></div>
                        </div>
                    )) : null}

                </Paper>


                {opcs ? opcs.filter((item) => item.tipo === "1").length > 0 ? (

                    // <Text style={styles.mainTextos}>Adicionais</Text>

                    <Paper className={styles.dropDownButton} onClick={() => setVisibleAdicionais(!visibleAdicionais)}>
                        <h4 className={'styles.mainTextos'}>Adicionais</h4>
                        <div className={'styles.dropDownButtonView'}>
                            {visibleAdicionais ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </div>
                    </Paper>

                ) : null : null}


                <Paper style={{ display: opcs.filter((item) => item.tipo === "1").length > 0 ? 'flex' : 'none' }} className={styles.opcoesView}>

                    {opcs && visibleAdicionais ? opcs.filter((opc) => opc.tipo === "1").map((opc, index) => (
                        <div key={opc.id} className={styles.itemView}>
                            <div style={{ width: '100%' }}>
                                <p style={{ fontWeight: 'bold' }}>{opc.descricao}</p>
                                <p style={{ fontSize: 12 }}>{opc.valor}</p>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <IconButton onClick={() => handleDiminuiOpcoes(opc.id)}>
                                    <RemoveCircleOutlineIcon style={{ color: 'black' }} />
                                </IconButton>
                                <h4 style={{ fontSize: 17 }}>{opc.quantidade}</h4>
                                <IconButton onClick={() => handleSomaOpcoes(opc.id)}>
                                    <AddCircleOutlineIcon style={{ color: 'black' }} />
                                </IconButton>
                            </div>
                        </div>
                    )) : null}
                </Paper>

                <div className={styles.quantidadeArea}>
                    <IconButton onClick={() => {
                        const qtd = quantidade > 1 ? quantidade - 1 : quantidade;
                        setQuantidade(qtd);
                        somarTotal(qtd, opcs);
                    }}>
                        <RemoveCircleOutlineIcon style={{ color: 'black', fontSize: '50px' }} />
                    </IconButton>
                    <h3>{quantidade}</h3>
                    <IconButton onClick={() => {
                        const qtd = quantidade < 18 ? quantidade + 1 : quantidade;
                        setQuantidade(qtd);
                        somarTotal(qtd, opcs);
                    }}>
                        <AddCircleOutlineIcon style={{ color: 'black', fontSize: '50px' }} />
                    </IconButton>
                </div>
            </div>

            <div className={styles.confirmArea}>
                <div className={styles.confirmAreaDiv}>
                    <div className={styles.totalArea}>
                        <h3>Total</h3>
                        <p>{total}</p>
                    </div>

                    <Button onClick={handleAddItem} className={styles.cartButton}>
                        Adicionar ao Carrinho
                        <AddShoppingCartIcon />
                    </Button>
                </div>
            </div>


        </div>
    );
}

export default SelectModal;