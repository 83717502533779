import logo from './logo.svg';
import './App.css';
import Main from './Pages/Main';
import { CartProvider } from './Provider/CartContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Chart from './Pages/Chart';
import SendRequest from './Pages/SendRequest';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import Colors from './Styles/Colors';


const theme = createTheme({
  palette: {
    secondary: {
      main: Colors.orange_light,
    }
  }
});

function App() {
  return (
    <CartProvider>
      <MuiThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/carrinho" element={<Chart />} />
          <Route path="/send" element={<SendRequest />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </Router>
      </MuiThemeProvider>
    </CartProvider>
  );
}

export default App;
