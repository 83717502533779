import { Button, makeStyles, Modal, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Colors from '../Styles/Colors';
import firebase from '../Services/firebase';
import SelectModal from './SelectModal';
const fontSize = 15;
const lineHeight = 1.2;
const linesShow = 2;

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        display: 'flex',
        margin: '25px auto',
        width: '90%',
        height: '80px',
        backgroundColor: Colors.orange_light,
        textAlign: 'left',
        alignItems: 'center',
        borderRadius: '50px 15px 15px 50px',

        '&:hover': {
            backgroundColor: Colors.dark_orange,

            '& img': {
                borderColor: Colors.dark_orange
            }
        }
    },

    textArea: {
        width:'100%',
        marginLeft: '105px',
        padding: '10px',
        '& h3': {
            margin: '0',
            display: 'inline-block',
        },
        '& p': {
            //  whiteSpace: 'nowrap',
            display: 'block',
            display: '-webkit-box',
            height: fontSize * lineHeight * linesShow, /* Fallback for non-webkit */
            margin: '0 auto',
            fontSize: fontSize,
            lineHeight: lineHeight,
            lineClamp: linesShow,
            boxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    nomeValor: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    valor: {
        // position: 'absolute',
        // right: '0px',
        // top: '0px',
        fontSize: '15px',
        color: 'black'
    },
    icon: {
        width: '100px',
        borderRadius: '100px',
        backgroundColor:Colors.dark_red,
        position: 'absolute',
        border: 'solid 5px',
        borderColor: Colors.orange_light
    },
}))

function Produto({ produto, tipo }) {

    //const [imgUrl, setImgUrl] = useState('');
    const [openModal, setOpenModal] = useState(false);


    // useEffect(() => {
    //     console.log(produto.tipo);
    //     firebase.storage().ref(`Tipos/${produto.tipo}.jpg`).getDownloadURL().then(resu => setImgUrl(resu));
    // }, [tipo])

    const styles = useStyles();

    const handleCloseModal = () => {
        setOpenModal(false);
      };

    return (
        <>
        <Paper onClick={() => setOpenModal(true)} elevation={3} className={styles.root}>
            <img className={styles.icon} src={`/categorias/${produto.tipo}.jpg`} />
            <div className={styles.textArea}>
                <div className={styles.nomeValor}>
                    <h3>{produto.nome}</h3>
                    {/* <h3 className={styles.valor}> {produto.valor}</h3> */}
                </div>
                <p>{produto.descricao}</p>
            </div>

            
        </Paper>
        <Modal
            
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
         
    >
        <SelectModal handleClose={handleCloseModal} produto={produto}/>
    </Modal>
    </>
    );
}

export default Produto;