import React, { useState, useEffect } from 'react';
import firebase from '../Services/firebase';
import { makeStyles } from '@material-ui/core';
import colors from '../Styles/Colors'
import Loading from './Loading';

const useStyles = makeStyles((theme) => ({
    root: {

        padding: '10px 15px',
        whiteSpace: 'nowrap',
        overflowX: 'scroll',
        //textAlign:'center',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    imgContainer: {
        display: 'inline-block',
        margin: '0 10px',
    },
    img: {
        width: '100%',
        maxHeight: '500px',
        borderRadius: '20px',
        borderColor: colors.orange_light,
        border: 'solid 5px',
    }
}))
function Carousel(props) {

    const styles = useStyles();

    const [imgList, setImgList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showImage, setShowImage] = useState(false);
    useEffect(() => {

        async function FecthImgs() {
            const li = await firebase.storage().ref('Banners').listAll();
            let novaLista = [];
            for (let id in li.items) {

                novaLista.push({ url: await li.items[id].getDownloadURL(), id: id });
            }
            setImgList(novaLista);
            //setLoading(false)
        }

        if (imgList.length <= 0) {
            FecthImgs();
        }



    }, [])

    return (
        <>
            <div className={styles.root} style={{ height: "250px", position: 'relative', display: loading ? 'block' : 'none' }}>
                <Loading />
            </div>

            <div className={styles.root} style={{display: loading ? 'none' : 'block'}}>

                {imgList.map((item) => (
                    <div className={styles.imgContainer}>
                        <img onLoad={() => setLoading(false)} className={styles.img} src={item.url} />
                    </div>
                ))}

            </div></>
    );
}

export default Carousel;